@import url('https://fonts.cdnfonts.com/css/montserrat');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #20315F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f0efef;
}
.logo-w {
  width: 300px;
}
.App-link {
  color: #61dafb;
}
.address {
  font-size: 10px;
  line-height:17px;
  font-weight: 300;
  opacity: 0;
  padding: 0px 40px;
}
.address b{ 
  font-weight: 500;
}
#t1, #t2 {
  opacity: 0;
}
.subTitle {
  pointer-events: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
